<template>
<div>
  <div class="container-fluid">
    <PageTitle noAdd />
    <div class="card">
      <div class="card-body">
          <!-- <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5> -->
          <h5 class="card-title mb-3">{{pageTitle}}</h5>
          
          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doSubmitCRUD)">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12" v-if="isLevelPerKantor">
                      <div class="form-group">
                        <label class="control-label">
                          Akun Level 
                          <span class="text-danger mr5">*</span>
                        </label>
                        <VSelect
                          v-model="input.levelId" 
                          placeholder="-Pilih Akun Level-"
                          :options="mrLevel"
                          :reduce="v=>String(v.value)" 
                        />
                        <VValidate name="Akun Level" v-model="input.levelId" :rules="mrValidation.levelId" />
                      </div>
                    </div>
                    <template v-if="isLevelPerKantor">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">
                            Kantor ID 
                            <span class="text-danger mr5">*</span>
                          </label>
                          <VSelect
                            v-model="input.officeId" 
                            placeholder="-Pilih Kantor ID-"
                            :options="mrKantor" 
                            :reduce="v=>String(v.value)" 
                          />
                          <VValidate name="Kantor ID" v-model="input.officeId" :rules="{required: isLevelPerKantor}" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">
                            Unit Kerja 
                            <span class="text-danger mr5">*</span>
                          </label>
                          <VSelect
                            v-model="input.unitKerjaId" 
                            placeholder="-Pilih Unit Kerja-"
                            :options="mrUnitKerja" 
                            :reduce="v=>String(v.value)" 
                          />
                          <VValidate name="Unit Kerja" v-model="input.unitKerjaId" :rules="{required: isLevelPerKantor}" />
                        </div>
                      </div>
                    </template>
                  </div>
                  <b-row>
                    <b-col md=6>
                      <div class="form-group">
                        <label class="control-label">
                          Nama Lengkap
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input 
                          v-model="input.fullName"
                          type="text" 
                          class="form-control" 
                          placeholder="e.g. John" 
                        /> 
                        <VValidate name="Nama Lengkap" v-model="input.fullName" :rules="mrValidation.fullName" />
                      </div>
                    </b-col>
                    <b-col md=6>
                      <div class="form-group">
                        <label class="control-label">
                          Nama Alias 
                        </label>
                        <input 
                          v-model="input.aliasName"
                          type="text" 
                          class="form-control" 
                          placeholder="e.g. John" 
                        /> 
                        <VValidate name="Nama Alias" v-model="input.aliasName" :rules="mrValidation.aliasName" />
                      </div>
                    </b-col>
                    <b-col md=6>
                      <div class="form-group">
                        <label class="control-label">
                          No Telp/HP
                        </label>
                        <input 
                          v-model="input.phone"
                          type="text" 
                          class="form-control" 
                          placeholder="e.g. 08xxxxx" 
                          maxlength="13"
                        /> 
                        <VValidate name="No Telp/HP" v-model="input.phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/ }" />
                      </div>
                    </b-col>
                    <b-col md=6>
                      <div class="form-group">
                        <label class="control-label">
                          Alamat Email 
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input 
                          v-model="input.email"
                          type="email" 
                          class="form-control" 
                          placeholder="e.g. joinemail@example.com" 
                        /> 
                        <VValidate name="Alamat Email" v-model="input.email" :rules="mrValidation.email" />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md=6>
                      <div class="form-group">
                        <label class="control-label">
                          Username 
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input 
                          v-model="input.username"
                          type="text" 
                          class="form-control" 
                          placeholder="e.g. john" 
                        /> 
                        <VValidate name="Username" v-model="input.username" :rules="mrValidation.username" />
                      </div>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Password
                          <a href="javascript:;" class="ml-1 icon-information" v-b-tooltip.hover.noninteractive="'Password harus mengandung abjad, angka, dan simbol. Minimal 8 karakter.'"><svg width="23" height="23" viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 17a7 7 0 100-14 7 7 0 000 14zm8-7a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd"></path><path d="M10.93 8.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533l1.002-4.705z"></path><circle cx="10" cy="6.5" r="1"></circle></svg></a>
                        </label>
                        <input 
                          v-model="input.password"
                          type="password" 
                          class="form-control" 
                        /> 
                        <VValidate name="Password" v-model="input.password" :rules="{required: true, regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/}" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Confirm Password 
                        </label>
                        <input 
                          v-model="input.password2"
                          type="password" 
                          class="form-control" 
                        /> 
                        <VValidate name="Confirm Password" v-model="input.password2" :rules="input.password ? 'required|confirmed:Password' : 'confirmed:Password'" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">
                      Foto Profil
                    </label>
                    <Uploader v-model="input.avatar" type="user" label="Foto"/>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2 offset-md-10">
                    <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
                </div>
              </div>
            </form>
          </ValidationObserver>

        </div>
    </div>
  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import Gen from '@libs/Gen'

let $ = global.jQuery
let _ = global._

export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      input:{},
      mrKantor: [],
      mrUnitKerja: [],
      mrLevel: []
    }
  },
  mounted(){
    this.apiGet()
    this.$set(this,'input', this.reparse(this.user))
  },
  computed:{
    isLevelPerKantor(){
      return _.filter(this.mrLevel, (v)=>{
        return v.value == this.input.bu_level && v.level_type=='PERKANTOR'
      }).length
    }
  },
  methods: {
    apiGet(params = {}){
      if(this.pageSlug) this.loadingOverlay = true
      else this.loadingOverlayTb = true
      this.data.data = false
      Gen.apiRest(
        "/get/Account/"+this.input.id
      ).then(res=>{
        this.loadingOverlay = false
        this.loadingOverlayTb = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        
        // if(!this.isList){
        //   this.setFocusForm()
        // }
        
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingOverlayTb = false
      })
    },
    doSubmitCRUD(IDForm='VForm'){
      return this.doSubmit(
        "/do/Account/"+this.input.id, 
        _.assign({type:'updateAccount'}, {id: this.input.id}, this.input), 
        (type, resp)=>{
          if(type=='success'){
            this.$store.commit('updateUser', resp.data.profile)
            this.$router.push({name:"Dashboard"})
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            }else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IDForm
      )
    },
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>